<template>
  <div class="relative flex text-gray-900" :class="{ hidden: shouldHide }">
    <template v-if="shouldHide === false">
      <SvgoStar v-for="star in amountFilledStars" :key="star" />
      <template v-for="star in amountNotFilledStars" :key="`not-filled-${star}`">
        <!-- first not filled star with amount -->
        <div v-if="star === 1" class="relative">
          <SvgoStar class="text-gray-300" />
          <div class="absolute bottom-0 left-0 right-0 top-0 overflow-hidden" :style="{ width: `${widthPercentage}%` }">
            <SvgoStar />
          </div>
        </div>
        <!-- empty stars -->
        <SvgoStar v-else class="text-gray-300" />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
const AMOUNT_MAX_STARS = 5;

const props = defineProps<{
  rating?: number;
}>();

const shouldHide = computed(() => props.rating === undefined || props.rating === 0);

const amountFilledStars = props.rating ? Math.floor(props.rating) : null;
const amountNotFilledStars = amountFilledStars ? Math.max(AMOUNT_MAX_STARS - amountFilledStars, 0) : null;

const widthPercentage = props.rating ? ((props.rating * 10) % 10) * 10 : null;
</script>

<style scoped></style>
